import { RefObject, useEffect, useState } from "react";
import { Product } from "../models/Product";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { colorsAtom } from "../models/Colors";
import ProductCard from "./ProductCard";
import { slugify } from "../functions";
import { faGrid2, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import _, { identity } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from "./Checkbox";
import { Filter } from "../models/Filter";
import Button from "./Button";

interface ProductList {
  products: Product[];
  ref?: RefObject<HTMLDivElement>;
  title?: string;
  loading?: boolean;
  full?: boolean;
  isGridView?: boolean;
  pageSize?: number;
  pageNr?: number;
  hideStock?: boolean;
  filters?: Filter[];
}

interface SortItem {
  name: string;
  label: string;
  function: (products: Product[]) => Product[];
}

const ProductList = (props: ProductList) => {
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isGridView, setIsGridView] = useState<boolean>(
    props.isGridView ?? window.innerWidth >= 768
  );
  const [products, setProducts] = useState<Product[]>([]);
  const [pageNr, setPageNr] = useState<number>(props.pageNr ?? 0);
  const [pageAmount, setPageAmount] = useState<number>(0);
  const [colors] = useAtom(colorsAtom);
  const [showSortMenu, setShowSortMenu] = useState<boolean>(false);
  const [sortItems, setSortItems] = useState<SortItem[]>([
    {
      name: "saz",
      label: window.location.pathname.includes("/favorites")
        ? "Aanmaakdatum"
        : window.location.pathname.includes("/my-products")
        ? "Laatst besteld"
        : "Standaard",
      function: (products) =>
        window.location.pathname.includes("/search-results")
          ? products
          : window.location.pathname.includes("/my-products")
          ? _.sortBy(products, ["sorting"]).reverse()
          : window.location.pathname.includes("/favorites")
          ? _.sortBy(products, ["favoriteId"]).reverse()
          : _.sortBy(products, [
              "productgroupname",
              "productname",
              "description",
            ]),
    },
    {
      name: "sza",
      label: window.location.pathname.includes("/favorites")
        ? "Aanmaakdatum (omgekeerd)"
        : window.location.pathname.includes("/my-products")
        ? "Laatst besteld (omgekeerd)"
        : "Standaard (omgekeerd)",
      function: (products) =>
        window.location.pathname.includes("/search-results")
          ? products.reverse()
          : window.location.pathname.includes("/my-products")
          ? _.sortBy(products, ["sorting"])
          : window.location.pathname.includes("/favorites")
          ? _.sortBy(products, ["favoriteId"])
          : _.sortBy(products, [
              "productgroupname",
              "productname",
              "description",
            ]).reverse(),
    },
    {
      name: "naz",
      label: "Naam (A-Z)",
      function: (products) => _.sortBy(products, ["description"]),
    },
    {
      name: "nza",
      label: "Naam (Z-A)",
      function: (products) => _.sortBy(products, ["description"]).reverse(),
    },
    {
      name: "caz",
      label: "Categorie (A-Z)",
      function: (products) => _.sortBy(products, ["productgroupname"]),
    },
    {
      name: "cza",
      label: "Categorie (Z-A)",
      function: (products) =>
        _.sortBy(products, ["productgroupname"]).reverse(),
    },
    {
      name: "paz",
      label: "Product (A-Z)",
      function: (products) => _.sortBy(products, ["productname"]),
    },
    {
      name: "pza",
      label: "Product (Z-A)",
      function: (products) => _.sortBy(products, ["productname"]).reverse(),
    },
  ]);
  const [currentSortItem, setCurrentSortItem] = useState<SortItem>(
    _.find(sortItems, (sortItem) => sortItem.name === urlParams.get("sort")) ??
      sortItems[0]
  );

  const [currentFilters, setCurrentFilters] = useState<
    {
      identifier: string;
      filters: string[];
    }[]
  >([]);
  const [expandedFilters, setExpandedFilters] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleFilterView = (identifier: string) => {
    setExpandedFilters((prev) => ({
      ...prev,
      [identifier]: !prev[identifier],
    }));
  };

  const onFavoriteChange = (id: string, isFavorite: boolean) => {
    setProducts(() =>
      currentProducts.map((product) =>
        product.id === id
          ? { ...product, isFavorite: isFavorite ? 1 : 0 }
          : product
      )
    );
  };

  useEffect(() => {
    setProducts(props.products);
  }, [props.products]);

  useEffect(() => {
    if (props.isGridView !== undefined) {
      setIsGridView(props.isGridView);
    }
  }, [props.isGridView]);

  useEffect(() => {
    setPageNr(props.pageNr ?? 0);
    if (props.pageSize) {
      setPageAmount(Math.ceil(currentProducts.length / props.pageSize));
    }
  }, [products, props.pageSize]);

  useEffect(() => {
    setCurrentSortItem(
      _.find(
        sortItems,
        (sortItem) => sortItem.name === urlParams.get("sort")
      ) ?? sortItems[0]
    );

    if (props.filters) {
      const initialFilters = props.filters.map((filter) => {
        const paramValue = urlParams.get(filter.identifier);
        const filterValues = paramValue
          ? paramValue.split(",").map(slugify)
          : [];
        return {
          identifier: filter.identifier,
          filters: filterValues,
        };
      });
      setCurrentFilters(initialFilters);
    }
  }, [id]);

  useEffect(() => {
    if (props.title) {
      urlParams.set("sort", currentSortItem.name);
      navigate(window.location.pathname + "?" + urlParams.toString());
      setShowSortMenu(false);
    }
  }, [currentSortItem]);

  useEffect(() => {
    if (props.filters) {
      props.filters.forEach((filter) => {
        const isInCurrentFilters = currentFilters.some(
          (currentFilter) => currentFilter.identifier === filter.identifier
        );

        if (!isInCurrentFilters) {
          urlParams.delete(filter.identifier);
        }
      });

      currentFilters.forEach((filter) => {
        urlParams.set(
          filter.identifier,
          filter.filters.map((item) => slugify(item)).join(",")
        );
      });

      navigate(
        window.location.pathname.replace(/\/\d+$/, `/${pageNr + 1}`) +
          "?" +
          urlParams.toString()
      );
      if (props.pageSize) {
        setPageAmount(Math.ceil(currentProducts.length / props.pageSize));
      }
    }
  }, [currentFilters]);

  const currentProducts = currentSortItem.function(
    products.filter((product) =>
      currentFilters.every(
        ({ identifier, filters }) =>
          filters.length === 0 ||
          filters.includes(
            slugify(product[identifier as keyof typeof product] as string)
          )
      )
    )
  );

  const onChangeFilter = (filter: any, identifier: string) => {
    setPageNr(0);
    setCurrentFilters((prevFilters) => {
      const slugifiedFilter = slugify(filter);
      const existingFilterGroup = _.find(prevFilters, {
        identifier,
      });

      if (existingFilterGroup) {
        const updatedFilters = existingFilterGroup.filters.includes(
          slugifiedFilter
        )
          ? _.without(existingFilterGroup.filters, slugifiedFilter)
          : [...existingFilterGroup.filters, slugifiedFilter];

        return updatedFilters.length
          ? _.map(prevFilters, (filterGroup) =>
              filterGroup.identifier === identifier
                ? {
                    ...filterGroup,
                    filters: updatedFilters,
                  }
                : filterGroup
            )
          : _.reject(prevFilters, { identifier });
      } else {
        return [
          ...prevFilters,
          {
            identifier,
            filters: [slugifiedFilter],
          },
        ];
      }
    });
  };

  const getFilterAmount = (identifier: string, filter: string) => {
    return _.size(
      _.filter(products, (product) => {
        const productValue = slugify(
          product[identifier as keyof typeof product] as string
        );
        const matchesCurrentFilter = productValue === slugify(filter);

        const matchesOtherFilters = _.every(
          currentFilters.filter((f) => f.identifier !== identifier),
          ({ identifier, filters }) =>
            filters.length === 0 ||
            filters.includes(
              slugify(product[identifier as keyof typeof product] as string)
            )
        );

        return matchesCurrentFilter && matchesOtherFilters;
      })
    );
  };

  return (
    <div className="flex flex-col md:flex-row gap-4">
      {props.filters ? (
        <>
          {/* Mobile filters */}
          <div className="flex w-full md:hidden flex-col gap-2 ml-4 pr-8 mt-8">
            {props.filters.map((item) => {
              const filterItems = _.sortBy(
                _.uniq(_.map(products, item.identifier))
              );
              return (
                <div
                  className="flex flex-row gap-2 overflow-x-scroll hide-scrollbar whitespace-nowrap"
                  key={item.identifier}
                >
                  <Button
                    label={`Alle ${item.display.toLowerCase()}`}
                    sm
                    gray={
                      !!_.find(currentFilters, { identifier: item.identifier })
                        ?.filters.length
                    }
                    active={
                      !_.find(currentFilters, { identifier: item.identifier })
                        ?.filters.length
                    }
                    onClick={() => {
                      setCurrentFilters((prevFilters) =>
                        prevFilters.map((filterGroup) =>
                          filterGroup.identifier === item.identifier
                            ? { ...filterGroup, filters: [] }
                            : filterGroup
                        )
                      );
                    }}
                  />
                  {filterItems.length > 1 ? (
                    <>
                      {filterItems.map(
                        (filter) =>
                          filter && (
                            <Button
                              key={filter}
                              label={`${filter} (${getFilterAmount(
                                item.identifier,
                                filter
                              )})`}
                              sm
                              gray={
                                !_.find(currentFilters, {
                                  identifier: item.identifier,
                                })?.filters.includes(slugify(filter))
                              }
                              active={
                                !!_.find(currentFilters, {
                                  identifier: item.identifier,
                                })?.filters.includes(slugify(filter))
                              }
                              onClick={() => {
                                onChangeFilter(filter, item.identifier);
                              }}
                            />
                          )
                      )}
                    </>
                  ) : (
                    <p
                      className="italic"
                      style={{ color: colors?.textLightColor }}
                    >
                      Geen beschikbare filters
                    </p>
                  )}
                </div>
              );
            })}
          </div>
          {/* Desktop filters */}
          <div className="hidden md:w-60 lg:w-72 md:flex flex-col gap-8 pt-14">
            {props.filters.map((item) => {
              const filterItems = _.compact(
                _.sortBy(_.uniq(_.map(products, item.identifier)))
              );
              const isExpanded = expandedFilters[item.identifier];

              const itemsToShow = _.compact(
                filterItems.map((filter) =>
                  filter !== null &&
                  (getFilterAmount(item.identifier, filter) > 0 ||
                    _.find(currentFilters, [
                      "identifier",
                      item.identifier,
                    ])?.filters.includes(filter.toLowerCase()))
                    ? filter
                    : null
                )
              );

              return (
                <div className="flex flex-col gap-3" key={item.identifier}>
                  <p className="text-lg font-bold mb-[3px]">{item.display}</p>
                  <hr />
                  <div className="flex flex-col gap-2">
                    {filterItems.length > 1 ? (
                      <>
                        {itemsToShow.slice(0, isExpanded ? undefined : 8).map(
                          (filter) =>
                            filter && (
                              <Checkbox
                                key={filter}
                                onChange={() => {
                                  onChangeFilter(filter, item.identifier);
                                }}
                                checked={
                                  !!_.find(currentFilters, {
                                    identifier: item.identifier,
                                  })?.filters.includes(slugify(filter))
                                }
                              >
                                <span className="text-sm">
                                  {filter} (
                                  {getFilterAmount(item.identifier, filter)})
                                </span>
                              </Checkbox>
                            )
                        )}
                        {itemsToShow.length > 8 && (
                          <p
                            onClick={() => toggleFilterView(item.identifier)}
                            className="text-sm my-2 font-bold cursor-pointer"
                            style={{ color: colors?.primaryColor }}
                          >
                            {isExpanded ? "Minder tonen" : "Alles tonen"}
                          </p>
                        )}
                      </>
                    ) : (
                      <p
                        className="italic"
                        style={{ color: colors?.textLightColor }}
                      >
                        Geen beschikbare filters
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      <div
        ref={props.ref}
        className="w-full mt-4 md:mt-8 flex flex-col gap-4 infinite-scroll"
      >
        {props.title ? (
          <div className="w-full flex flex-col sm:flex-row justify-between gap-4 items-center px-4 md:px-0">
            <p className="text-xl font-bold w-full">{props.title}</p>
            <div className="flex flex-row gap-4 items-center w-full sm:w-auto">
              <div className="relative w-full sm:w-auto">
                <button
                  className="rounded-full border py-3 px-5 flex flex-row gap-3 items-center w-full sm:w-56 justify-between"
                  style={{
                    borderColor: colors?.primaryColor,
                    color: colors?.primaryColor,
                  }}
                  onClick={() => setShowSortMenu(!showSortMenu)}
                >
                  <p className="line-clamp-1">{currentSortItem.label}</p>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`transition-all ${
                      showSortMenu ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>
                <div
                  className={`absolute w-full -bottom-2 right-0 px-5 py-3 transition-all z-30 shadow-lg bg-white rounded-lg flex flex-col items-start gap-3 ${
                    showSortMenu
                      ? "scale-100 translate-y-full"
                      : "scale-0 translate-y-1/2"
                  }`}
                >
                  {sortItems.map((sortItem) => (
                    <button
                      onClick={() => {
                        setCurrentSortItem(sortItem);
                        navigate(
                          window.location.pathname.replace(/\/\d+$/, `/1`) +
                            "?" +
                            urlParams.toString()
                        );
                      }}
                    >
                      {sortItem.label}
                    </button>
                  ))}
                </div>
              </div>
              <FontAwesomeIcon
                icon={isGridView ? faBars : faGrid2}
                className="text-2xl cursor-pointer"
                style={{ color: colors?.primaryColor }}
                onClick={() => setIsGridView(!isGridView)}
                fixedWidth
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`grid ${
            isGridView
              ? `grid-cols-1 xs:grid-cols-2 ${
                  props.full
                    ? "sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
                    : "sm:grid-cols-3 lg:grid-cols-4"
                } gap-4 px-4 md:px-0`
              : "grid-cols-1 divide-y"
          }`}
        >
          {currentProducts
            .slice(
              pageNr * (props.pageSize ?? 1),
              (pageNr + 1) * (props.pageSize ?? 1)
            )
            .map((product, id) => (
              <div
                className={`${isGridView ? "border rounded" : ""}`}
                style={{ borderColor: colors?.neutralColor }}
                key={`prlstcrd-${id}`}
              >
                <ProductCard
                  key={`${slugify(product.title)}-product-card-${product.id}`}
                  statuscode={product.statuscode}
                  status={product.status}
                  brand={product.brand !== "" ? product.brand : "Merkloos"}
                  label={product.description}
                  thumbnailUrl={product.thumbnailUrl}
                  price={product.price}
                  to={`/product/${slugify(
                    product.mainProductDescription ||
                      product.mainItemDescription ||
                      product.description
                  )}/${
                    product.mainItemId
                      ? `${product.mainItemId}/${product.id}`
                      : product.id
                  }`}
                  id={product.id}
                  amount={
                    product.number !== undefined &&
                    typeof product.number === "number"
                      ? product.number
                      : 0
                  }
                  onFavoriteChange={onFavoriteChange}
                  isFavorite={product.isFavorite === 1}
                  variants={product.mainitem}
                  itemcode={product.itemcode}
                  backorder={product.backorder}
                  coworkersNumber={product.coworkersNumber}
                  list={!isGridView}
                  activeStatus={product.activeStatus}
                  hideStock={props.hideStock}
                  readyMadeArticle={product.readyMadeArticle}
                  optional={product.optional}
                />
              </div>
            ))}
        </div>
        {props.loading && (
          <div className="mx-auto flex my-8 w-fit">
            <FontAwesomeIcon
              icon={faSpinnerThird}
              className="mt-4 animate-spin duration-1000 text-xl"
              style={{ color: colors?.neutralColor }}
            />
          </div>
        )}
        {!props.loading && products.length === 0 && (
          <p
            className="mx-auto flex my-8 w-fit italic"
            style={{ color: colors?.neutralColor }}
          >
            {t("common.no_products_found")}
          </p>
        )}
        {props.pageSize && pageAmount > 1 && (
          <div className="flex flex-row gap-3 justify-center items-center mt-8 mb-16">
            {[...Array(pageAmount)].map((_, i) =>
              i === pageNr ||
              i === pageNr - 1 ||
              i === pageNr + 1 ||
              i === 0 ||
              i === pageAmount - 1 ||
              pageAmount <= 5 ? (
                <Link
                  key={i}
                  className="cursor-pointer flex items-center rounded-full w-10 h-10 border"
                  style={{
                    borderColor:
                      i === pageNr
                        ? colors?.primaryColor
                        : colors?.neutralColor,
                  }}
                  to={`${window.location.pathname.replace(
                    /\/\d+$/,
                    `/${i + 1}`
                  )}?${urlParams.toString()}`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span className="w-fit mx-auto">{i + 1}</span>
                </Link>
              ) : i === pageAmount - 2 || i === 1 ? (
                <span key={i}>...</span>
              ) : null
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList;

// Models
import { Cart, CartPerUser } from "./models/Cart";
import { Category } from "./models/Category";
import { Product, ProductsPerCategory } from "./models/Product";
import { Session } from "./models/Session";
import { User } from "./models/User";
import { CarouselData } from "./models/CarouselData";

// Font
import "@fontsource/noto-sans";
import { OrderHistory } from "./models/OrderHistory";
import { Order } from "./models/Order";
import { Brand } from "./models/Brand";
import { SearchTerm, SearchTermCollection } from "./models/Search";
import { Address } from "./models/Address";
import _ from "lodash";

// Base route
const BASE_URL = "https://altacprodqueryfactory.azurewebsites.net";
const FACTORY_STRING = "api/Factory/execute/QF_Connection";

// GET Routes
const GET_STYLE_CONFIG = "getStyleConfig";
const GET_CART = "getShoppingcart";
const GET_MOST_RECENT_PRODUCTS = "getMostRecentProducts";
const GET_CATEGORIES = "getCategories";
const GET_CATEGORIES_BY_SEARCHTERM_BRAND = "getCategoriesBySearchTermBrand";
const GET_MOST_BOUGHT_BY_CATEGORY = "getMostBoughtByCategory";
const GET_USER_INFO = "getUserInfo";
const GET_BANNER = "getBanner";
const GET_BANNER_PRODUCTS = "getBannerProducts";
const GET_BRANDS_BY_PRODUCTGROUPID = "getBrandsByProductGroupId";
const GET_BRANDS_BY_SEARCHTERM = "getBrandsBySearchTerm";
const GET_BRANDS_BY_SEARCHTERM_GROUP = "getBrandsBySearchTermGroup";
const GET_SUBCATEGORIES_BY_SEARCHGROUP = "getSubcategoriesBySearchTermGroupId";
const GET_CATEGORIES_BY_SEARCHTERM = "getCategoriesBySearchTerm";
const GET_ACTION = "getAction";
const GET_ACTION_PRODUCTS = "getActionProducts";
const GET_SEARCH_TERM = "getSearchTerm";
const GET_SEARCH_TERM_V2 = "getSearchTermV2";
const GET_SEARCH_HISTORY = "getSearchHistory";
const GET_PRODUCT_BY_EAN = "getProductByEan";
const GET_ORDER_HISTORY = "getOrderHistory";
const GET_PRODUCT_ORDERED_HISTORY = "getProductOrderedHistory";
const GET_PRODUCT_DISCOUNT = "getProductDiscounts";
const GET_MOST_BOUGHT_CATEGORY = "getMostBoughtCategories";
const GET_MOST_BOUGHT_PRODUCTS_BY_CATEGORY = "getMostBoughtProductsByCategory";
const GET_BRAND_PRODUCTS = "getBrandProducts";
const GET_FEATURED_PRODUCTS = "getFeaturedProducts";
const GET_NEW_FOR_YOU_PRODUCTS = "getNewForYou";
const GET_PRODUCT_VARIATION = "getProductVariationProducts";
const GET_ADVISORY_PRODUCT = "getAdvisoryProduct";
const GET_SUBCATEGORIES = "getSubCategories";
const GET_SUBCATEGORIES_BY_BRAND = "getSubCategoriesByBrand";
const GET_BRANDS_BY_CATEGORY = "getBrandByCategoryIdFilterable";
const GET_SUBCATEGORIES_FILTERABLE = "getProductByCategoryIdFilterable";
const GET_PRODUCT_BY_CATEGORYID_BRANDID_FILTERABLE =
  "getProductByCategoryIdBrandIdFilterable";
const GET_PRODUCT_BY_CATEGORYID = "getProductByCategoryId";
const GET_LAST_TEN_ORDERS = "GetLastTenOrders";
const GET_PRODUCTS_HOME_MOBILE = "getProductsHomeMobile";
const EMPTY_CART = "emptyShoppingCartV2";
const GET_PRODUCT_ATTACHMENTS = "getProductAttachmentsByProductId";
const GET_SHOPPING_CART_COUNT = "getShoppingCartCount";
const GET_FAVORITES_FILTERABLE = "getFavoritesFilterable";
const GET_FAVORITES = "getFavorites";
const GET_PRODUCTS_BY_BRAND = "getProductsByBrand";
const GET_PRODUCTTYPES_BY_BRAND = "getProductTypesByBrand";
const GET_PRODUCTS_BY_BRAND_TYPE = "getProductsByBrandType";
const GET_PRODUCT_BRANDS = "getProductBrands";
const GET_PRODUCT_BY_ID = "getProductById";
const GET_PRODUCT_BY_SEARCH_TERM = "getProductsBySearchTerm";
const GET_PRODUCTS_BY_SEARCHTERM_GROUPID = "getProductsBySearchTermGroupId";
const GET_PRODUCTS_BY_SEARCHTERM_TYPEID = "getProductsBySearchTermTypeId";
const GET_PRODUCTS_BY_SEARCHTERM_BRAND = "getProductsBySearchTermBrand";
const GET_PRODUCTS_BY_SEARCHTERM_GROUP = "getProductsBySearchTermGroup";
const GET_PRODUCTS_BY_SEARCHTERM_BRAND_GROUP =
  "getProductsBySearchTermGroupBrand";
const GET_CUSTOMERS = "getCustomers";
const GET_MOST_BOUGHT_PRODUCTS_DESKTOP = "getMostBoughtProductsDesktop";
const GET_BACKORDERS_FOR_CUSTOMER = "GetBackordersForCustomer";
const GET_CATEGORIES_GROUPED = "GetCategoriesGrouped";
const GET_BANNER_BY_ID = "getBannerById";
const GET_PRODUCTS_SAME_GROUP_FROM_PRODUCT_ID =
  "getProductsWithSameProductGroupFromProductId";
const GET_CATEGORY_BY_ID = "getCategoryById";
const GET_BRAND_BY_ID = "getBrandById";
const GET_CONTACT_SPECIFIC_PRODUCT_DATA_BY_IDS =
  "GetContactSpecificProductDataByIds";
const GET_MOST_BOUGHT_PRODUCTS_FILTER =
  "getMostBoughtProductsDesktopFilterable";
const GET_DELIVERY_ADDRESSES = "GetDeliveryAddresses";
const GET_PRODUCTS_VAAK_SAMEN_GEKOCHT = "GetProductsVaakSamenGekocht";
const GET_PRODUCTS_ANDERE_SOORTEN = "GetProductsAndereSoorten";
const GET_PRODUCTS_GEKOPPELDE_POPUP = "GetProductsGekoppeldePopup";
const GET_CUSTOMER_SPECIFIC_PRODUCTS = "getCustomerSpecificProducts";
const GET_ACTIVE_BRANDS = "GetActiveBrands";
const GET_LINKED_CUSTOMERS = "getLinkedCustomers";
const GET_LISTS = "getLists";
const GET_LIST_PRODUCTS = "getListProducts";
const GET_LIST_NAMES = "getListNames";
const GET_LIST_NAMES_V2 = "getListNamesV2";

// POST routes
const POST_SHOPPING_CART = "postShoppingCart";
const POST_SHOPPING_CART_BY_CARTID = "postShoppingCartV2";
const POST_SEARCH_HISTORY = "postSearchHistory";
const COMPLETE_SHOPPING_CART = "completeShoppingcartV2";
const POST_FAVORITE = "postFavorite";
const COMPLETE_SHOPPING_CART_INVOICE = "completeShoppingCartAsInvoiceV2";
const LOGIN_AS_CLIENT = "loginAsCustomer";
const GET_MAIN_ITEM_BY_PRODUCT_ID = "getMainItemByProductId";
const CHANGE_PASSWORD = "api/Account/ChangePassword";
const POST_DELIVERY_ADDRESSES = "PostDeliveryAddresses";
const SWITCH_LINKED_CUSTOMER = "switchLinkedCustomer";
const ADD_LIST = "addList";
const ADD_PRODUCT_TO_LIST = "addProductToList";
const ADD_PRODUCT_TO_LIST_V2 = "addProductToListV2";
const ADD_PRODUCT_TO_LIST_V3 = "addProductToListV3";
const ADD_LIST_TO_CART = "addListToCart";
const DELETE_LIST = "deleteList";
const EDIT_LIST = "editList";
const EDIT_LIST_PRODUCT_COUNT = "editListProductCount";
const EDIT_LIST_PRODUCT_INSTRUCTION = "editListProductInstruction";

// DELETE routes
const DELETE_SEARCH_HISTORY = "deleteSearchHistory";
const DELETE_FAVORITE = "deleteFavorite";
// UPDATE routes
const UPDATE_SHOPPING_CART = "updateShoppingCart";

export const getStyleConfig = async (): Promise<
  | {
      primaryColor: string;
      primaryLightColor: string;
      neutralColor: string;
      neutralLightColor: string;
      textLightColor: string;
      inStockColor: string;
      limitedStockColor: string;
      outOfStockColor: string;
      squareLogo: string;
      whiteLogo: string;
      placeholder: string;
    }
  | undefined
> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_STYLE_CONFIG}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    let data = await response.json();
    if (data) {
      data = data.items[0];
      return {
        primaryColor: data.primaryColor,
        primaryLightColor: data.primaryLightColor,
        neutralColor: data.neutralColor,
        neutralLightColor: data.neutralLightColor,
        textLightColor: data.textLightColor,
        inStockColor: data.inStockColor,
        limitedStockColor: data.limitedStockColor,
        outOfStockColor: data.outOfStockColor,
        squareLogo: data.squareLogo,
        whiteLogo: data.whiteLogo,
        placeholder: data.placeholder,
      };
    }
    return undefined;
  } catch {
    return {
      primaryColor: "#E53012",
      primaryLightColor: "#FCEAE7",
      neutralColor: "#D9D9D9",
      neutralLightColor: "#F6F6F6",
      textLightColor: "#808080",
      inStockColor: "#7DC776",
      limitedStockColor: "#F38A29",
      outOfStockColor: "#D41717",
      squareLogo:
        "https://qfaltacpstorage.blob.core.windows.net/files/b911e692-273e-4b07-a7aa-2032b71d4bdd-altac_square.png",
      whiteLogo:
        "https://qfaltacpstorage.blob.core.windows.net/files/bd62d89d-60bd-48c2-b2f5-4f296e02aaec-altac_white.png",
      placeholder:
        "https://qfaltacpstorage.blob.core.windows.net/files/d4892874-f026-4188-8d15-993525b923b8-product-placeholder.png",
    };
  }
};

export const fetchCart = async (
  token: Session,
  username: string
): Promise<CartPerUser[]> => {
  try {
    const response = await fetch(`${BASE_URL}/${FACTORY_STRING}/${GET_CART}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
    });
    const data = await response.json();
    if (data.success && data.items) {
      let cartsPerUser: CartPerUser[] = [
        {
          name: username,
          type: "user",
          products: [],
        },
      ];

      data.items.map((cartItem: Cart) => {
        const cartUserIndex = cartsPerUser.findIndex(
          (cartUserItem) =>
            (cartUserItem.name === cartItem.username &&
              cartItem.listName === null) ||
            cartUserItem.name === cartItem.listName
        );

        if (cartUserIndex >= 0) {
          cartsPerUser[cartUserIndex].products.push(cartItem);
        } else {
          cartsPerUser.push({
            name: cartItem.listName ?? cartItem.username,
            type: cartItem.listName ? "list" : "user",
            products: [cartItem],
          });
        }
      });

      return cartsPerUser;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const fetchMostRecentProducts = async (
  token: Session
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_RECENT_PRODUCTS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const fetchCategories = async (token: Session): Promise<Category[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CATEGORIES}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductsByBrandGroup = async (
  token: Session,
  brand: string,
  groupId: number
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_BRAND}?brandid=${brand}&groupid=${groupId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductTypesByBrand = async (
  token: Session,
  brandId: string
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTTYPES_BY_BRAND}?brandid=${brandId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductsByBrandType = async (
  token: Session,
  brandId: number,
  typeId: string
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_BRAND_TYPE}?brandid=${brandId}&typeid=${typeId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const fetchMostRecentBoughtByCategory = async (
  token: Session,
  category: string
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_BY_CATEGORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ category }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const fetchOrderHistory = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_ORDER_HISTORY}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getUserInfo = async (
  token: Session
): Promise<User | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_USER_INFO}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items && data.items.length > 0) {
      return data.items[0];
    }

    return undefined;
  } catch (error) {
    console.error("Error User information: ", error);
    return undefined;
  }
};

export const getBannerProducts = async (
  token: Session,
  bannerId: number
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BANNER_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          bannerid: bannerId,
        }),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    if (data) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getBanner = async (token: Session): Promise<CarouselData[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BANNER}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error fetching banner: ", error);
    return [];
  }
};

export const getSearchTerms = async (
  token: Session,
  value: string
): Promise<SearchTermCollection> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SEARCH_TERM_V2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          term: value,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      let searchTerms: SearchTermCollection = {
        products: [],
        others: [],
      };

      data.items.map((searchTerm: SearchTerm) => {
        switch (searchTerm.type) {
          case 2:
            searchTerms.products.push(searchTerm);
            break;
          default:
            searchTerms.others.push(searchTerm);
            break;
        }
      });

      return searchTerms;
    }

    return {
      products: [],
      others: [],
    };
  } catch (error) {
    console.error("Error fetching search suggestions: ", error);
    return {
      products: [],
      others: [],
    };
  }
};

export const fetchSearchHistory = async (
  token: Session
): Promise<{ term: string; id: number }[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SEARCH_HISTORY}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error fetching search history: ", error);
    return [];
  }
};

export const postSearchHistory = async (token: Session, value: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${POST_SEARCH_HISTORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          term: value,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error posting search history: ", error);
    return [];
  }
};

export const deleteSearchHistory = async (
  token: Session,
  searchBarItem: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${DELETE_SEARCH_HISTORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id: searchBarItem,
        }),
      }
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getSubCategoriesByBrand = async (
  token: Session,
  brand: string,
  productGroupId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SUBCATEGORIES_BY_BRAND}?productgroupid=${productGroupId}&brand=${brand}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTermBrand = async (
  token: Session,
  term: string,
  brand: string,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_SEARCHTERM_BRAND}?brand=${brand}&term=${term}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTermGroup = async (
  token: Session,
  term: string,
  groupId: number,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_SEARCHTERM_GROUP}?groupid=${groupId}&term=${term}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTermGroupBrand = async (
  token: Session,
  term: string,
  groupId: number,
  brand: string,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_SEARCHTERM_BRAND_GROUP}?brand=${brand}&groupid=${groupId}&term=${term}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductById = async (
  token: Session,
  id: number
): Promise<Product | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BY_ID}?id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.items[0];
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const updateShoppingCart = async (
  session: Session,
  number: number,
  id: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${UPDATE_SHOPPING_CART}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.accessToken}`,
        },
        body: JSON.stringify({
          number: number,
          id: id,
          instruction: "",
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items[0];
    }

    return [];
  } catch (error) {
    console.error("Error updating shopping cart: ", error);
    return [];
  }
};

export const getCategoriesBySearchTermBrand = async (
  token: Session,
  term: string,
  brand: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CATEGORIES_BY_SEARCHTERM_BRAND}?term=${term}&brand=${brand}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getBrandsBySearchTermGroup = async (
  token: Session,
  term: string,
  groupId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRANDS_BY_SEARCHTERM_GROUP}?term=${term}&groupid=${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getBrandsBySearchTerm = async (token: Session, term: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRANDS_BY_SEARCHTERM}?term=${term}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getBrandsByProductGroupId = async (token: Session, id: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRANDS_BY_PRODUCTGROUPID}?productgroupid=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getSubcategoriesBySearchTermGroupId = async (
  token: Session,
  searchTerm: string,
  groupId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SUBCATEGORIES_BY_SEARCHGROUP}?term=${searchTerm}&groupid=${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTermGroupId = async (
  token: Session,
  searchTerm: string,
  groupId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_SEARCHTERM_GROUPID}?term=${searchTerm}&groupid=${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTermTypeId = async (
  token: Session,
  searchTerm: string,
  groupId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_BY_SEARCHTERM_TYPEID}?term=${searchTerm}&typeid=${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getCategoriesBySearchTerm = async (
  token: Session,
  searchTerm: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CATEGORIES_BY_SEARCHTERM}?term=${searchTerm}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const getProductsBySearchTerm = async (
  token: Session,
  searchTerm: string,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BY_SEARCH_TERM}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          term: searchTerm,
          pageSize: pageSize,
          pageNumber: pageNumber,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const completeShoppingcart = async (
  session: Session,
  ids: string,
  orderInstruction: string,
  addressline: string,
  postcode: string,
  city: string,
  country: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${COMPLETE_SHOPPING_CART}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.accessToken}`,
        },
        body: JSON.stringify({
          ids,
          orderInstruction,
          addressline,
          postcode,
          city,
          country,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }

    return [];
  } catch (error) {
    console.error("Error completing the shopping cart: ", error);
    return [];
  }
};

export const getFeaturedProducts = async (
  token: Session,
  pageNumber: number,
  pageSize: number
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_FEATURED_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          pageNumber,
          pageSize,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error getting featured products: ${response.status}`);
    }
    const data = await response.json();
    if (data) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error getFeaturedProducts", error);
    return [];
  }
};

export const getAction = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_ACTION}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error getting product by search term: ${response.status}`
      );
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error getAction", error);
    return undefined;
  }
};

export const getActionProducts = async (token: Session, id: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_ACTION_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          actionid: id,
        }),
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const postFavorite = async (token: Session, id: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${POST_FAVORITE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const deleteFavorite = async (token: Session, id: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${DELETE_FAVORITE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductByEan = async (token: Session, eanCode: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BY_EAN}?ean=${eanCode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductAttachmentsByProductId = async (
  token: Session,
  productId: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_ATTACHMENTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          productId,
        }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return data.items;
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getFavoritesFilterable = async (token: Session, catid: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_FAVORITES_FILTERABLE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getFavorites = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_FAVORITES}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchProductOrderedHistory = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_ORDERED_HISTORY}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error fetching product history: ", error);
    return [];
  }
};

export const fetchProductDiscounts = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_DISCOUNT}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error getting discount products: ${response.status}`);
    }
    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error getProductDiscounts", error);
    return undefined;
  }
};

export const fetchMostBoughtCategories = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_CATEGORY}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error fetching most bought categories: ", error);
    return [];
  }
};

export const fetchMostBoughtByCategory = async (
  token: Session,
  categoryId: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_PRODUCTS_BY_CATEGORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          pgid: categoryId,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error fetching most bought products by categories: ", error);
    return [];
  }
};

export const fetchProductBrands = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BRANDS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error fetching product brands: ", error);
    return [];
  }
};

export const getBrandProducts = async (token: Session, brandid: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRAND_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ brandid }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error fetching product brands: ", error);
    return [];
  }
};

export const getNewForYou = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_NEW_FOR_YOU_PRODUCTS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Error getting new products: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error getNewForYou", error);
    return [];
  }
};

export const getAdvisoryProduct = async (token: Session, productId: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_ADVISORY_PRODUCT}?productid=${productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error getting advisory product: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error getting advisory product", error);
    return [];
  }
};

export const getProductVariationProducts = async (
  token: Session,
  mainitemcode: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_VARIATION}?mainitemcode=${mainitemcode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error getting product variations: ${response.status}`);
    }

    const data = await response.json();
    return data.items;
  } catch (error) {
    console.error("Error getProductVariationProducts", error);
    return [];
  }
};

export const getSubCategories = async (
  token: Session,
  productgroupid: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SUBCATEGORIES}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ productgroupid }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchLastTenOrders = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_LAST_TEN_ORDERS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      const groupedItems: { [key: string]: OrderHistory[] } = data.items.reduce(
        (acc: { [key: string]: OrderHistory[] }, item: OrderHistory) => {
          const { ordernr } = item;
          if (!acc[ordernr]) {
            acc[ordernr] = [];
          }
          acc[ordernr].push(item);
          return acc;
        },
        {}
      );

      const result: Order[] = Object.keys(groupedItems).map(
        (ordernr: string) => {
          const products = groupedItems[ordernr];
          const orddat = products[0].orddat;
          const amount = products.reduce((totalAmount, product) => {
            // Assuming product has an 'amount' property
            return totalAmount + (product.amount || 0);
          }, 0);

          return {
            ordernr,
            orddat,
            products,
            amount,
          };
        }
      );

      return result.reverse();
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const emptyCart = async (token: Session, ids: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${EMPTY_CART}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          ids,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
  } catch (error) {}
};

export const postShoppingCart = async (
  token: Session,
  productId: number | string,
  number: number,
  instruction: string | undefined
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${POST_SHOPPING_CART}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          productId,
          number,
          instruction,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const postShoppingCartByCartId = async (
  token: Session,
  scid: number | string,
  number: number,
  instruction: string | undefined
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${POST_SHOPPING_CART_BY_CARTID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          scid,
          number,
          instruction,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const completeShoppingCartAsInvoice = async (
  session: Session,
  ids: string,
  orderInstruction: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${COMPLETE_SHOPPING_CART_INVOICE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.accessToken}`,
        },
        body: JSON.stringify({
          ids,
          orderInstruction,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }

    return [];
  } catch (error) {
    console.error("Error completing the shopping cart with invoice: ", error);
    return [];
  }
};

export const getProductsHomeMobile = async (
  token: Session,
  categoryId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_HOME_MOBILE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          pgid: categoryId,
        }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      let recentData: Product[] = [];
      let byCategoryData: Product[] = [];
      let discountData: Product[] = [];

      data.items.forEach((product: Product) => {
        if (product.widget === "recent") {
          recentData.push(product);
        } else if (product.widget === "bycategory") {
          byCategoryData.push(product);
        } else if (product.widget === "discount") {
          discountData.push(product);
        }
      });

      return {
        recent: recentData,
        byCategory: byCategoryData,
        discount: discountData,
      };
    }
  } catch (error) {}
};

export const getShoppingCartCount = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SHOPPING_CART_COUNT}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Error getting shopping cart count: ${response.status}`);
    }

    const data = await response.json();
    return data.items[0].shoppingCartCount;
  } catch (error) {
    console.error("Error: ", error);
  }
};

export const getProductByCategoryIdBrandIdFilterable = async (
  token: Session,
  catid: number,
  subid: number,
  brand: string,
  PageSize: number,
  PageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BY_CATEGORYID_BRANDID_FILTERABLE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid, subid, brand, PageSize, PageNumber }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getProductByCategoryIdFilterable = async (
  token: Session,
  catid: number,
  subid: number,
  PageSize: number,
  PageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_SUBCATEGORIES_FILTERABLE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid, subid, PageSize, PageNumber }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getProductByCategoryId = async (
  token: Session,
  catid: number,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCT_BY_CATEGORYID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid, pageSize, pageNumber }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getBrandByCategoryIdFilterable = async (
  token: Session,
  catid: number,
  subid: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRANDS_BY_CATEGORY}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid, subid }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const fetchContactUsers = async (
  token: Session,
  PageNumber: number,
  PageSize: number,
  SearchText: string
) => {
  try {
    const endpoint = `${BASE_URL}/${FACTORY_STRING}/${GET_CUSTOMERS}`;

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
      body: JSON.stringify({ PageNumber, PageSize, SearchText }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const loginAsCustomer = async (session: Session, customerId: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${LOGIN_AS_CLIENT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.accessToken}`,
        },
        body: JSON.stringify({
          customerId,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getMainItemByProductId = async (token: Session, id: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MAIN_ITEM_BY_PRODUCT_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export const getMostBoughtProductsDesktopOLD = async (
  token: Session
): Promise<ProductsPerCategory[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_PRODUCTS_DESKTOP}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      let productsPerCategory: ProductsPerCategory[] = [];

      data.items.map((productPerCategory: Product) => {
        const productIndex = productsPerCategory.findIndex(
          (categoryItem) => categoryItem.pgname === productPerCategory.pgname
        );

        if (productIndex >= 0) {
          productsPerCategory[productIndex].products.push(productPerCategory);
        } else {
          productsPerCategory.push({
            pgname: productPerCategory.pgname ?? "",
            products: [productPerCategory],
          });
        }
      });

      return productsPerCategory;
    }
    return [];
  } catch (error) {
    console.error("Error", error);
    return [];
  }
};

export const getMostBoughtProductsDesktop = async (
  token: Session,
  pageNumber: number,
  pageSize: number
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_PRODUCTS_DESKTOP}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          pageNumber,
          pageSize,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error getting my products: ${response.status}`);
    }
    const data = await response.json();
    if (data) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error getMostBoughtProductsDesktop", error);
    return [];
  }
};

export const getCategoriesGrouped = async (
  token: Session
): Promise<{ group: string; items: Category[] }[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CATEGORIES_GROUPED}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      const groupedItems: {
        [key: string]: { group: string; items: Category[] };
      } = {};

      data.items.forEach((item: Category) => {
        if (!groupedItems[item.group]) {
          groupedItems[item.group] = {
            group: item.group,
            items: [],
          };
        }
        groupedItems[item.group].items.push({
          id: item.id,
          group: item.group,
          name: item.name,
          thumbnailUrl: item.thumbnailUrl,
          imageUrl: item.imageUrl,
        });
      });

      const resultArray = Object.values(groupedItems);

      return resultArray;
    }
    return [];
  } catch (error) {
    console.error("Error", error);
    return [];
  }
};

export const getBannerById = async (
  token: Session,
  id: number
): Promise<CarouselData | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BANNER_BY_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }

    return undefined;
  } catch (error) {
    console.error("Error fetching banner: ", error);
    return undefined;
  }
};

export const getProductsWithSameProductGroupFromProductId = async (
  token: Session,
  id: string,
  pageNumber: number,
  pageSize: number
): Promise<Product[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_SAME_GROUP_FROM_PRODUCT_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
          pageNumber,
          pageSize,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error fetching banner: ", error);
    return [];
  }
};

export const getCategoryById = async (
  token: Session,
  id: number
): Promise<Category | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CATEGORY_BY_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }

    return undefined;
  } catch (error) {
    console.error("Error fetching category: ", error);
    return undefined;
  }
};

export const getBrandById = async (
  token: Session,
  id: number
): Promise<Brand | undefined> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BRAND_BY_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items[0];
    }

    return undefined;
  } catch (error) {
    console.error("Error fetching brand: ", error);
    return undefined;
  }
};

export const getContactSpecificProductDataByIds = async (
  token: Session,
  username: string,
  ids: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CONTACT_SPECIFIC_PRODUCT_DATA_BY_IDS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          username,
          ids,
        }),
      }
    );

    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error fetching product data: ", error);
    return [];
  }
};

export const getMostBoughtFilterable = async (
  token: Session,
  catid: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_MOST_BOUGHT_PRODUCTS_FILTER}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ catid }),
      }
    );
    const data = await response.json();

    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.log("Error getting getMostBoughtFilterable: ", error);
    return [];
  }
};

export const getBackordersForCustomer = async (
  token: Session
): Promise<ProductsPerCategory[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_BACKORDERS_FOR_CUSTOMER}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      let productsPerCategory: ProductsPerCategory[] = [];

      data.items.map((productPerCategory: Product) => {
        const productIndex = productsPerCategory.findIndex(
          (categoryItem) =>
            categoryItem.pgname === productPerCategory.productgroupname
        );

        if (productIndex >= 0) {
          productsPerCategory[productIndex].products.push(productPerCategory);
        } else {
          productsPerCategory.push({
            pgname: productPerCategory.productgroupname ?? "",
            products: [productPerCategory],
          });
        }
      });

      return productsPerCategory;
    }
    return [];
  } catch (error) {
    console.error("Error", error);
    return [];
  }
};

export const changePassword = async (
  token: Session,
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
): Promise<string[] | undefined> => {
  try {
    const response = await fetch(`${BASE_URL}/${CHANGE_PASSWORD}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
      body: JSON.stringify({
        oldPassword,
        newPassword,
        confirmPassword,
      }),
    });

    const data = await response.json();

    if (data.status === 200) {
      return undefined;
    } else {
      if (data.errors) {
        let errorKeys: string[] = [];
        for (var key in data.errors) {
          errorKeys.push(key);
        }
        return errorKeys.map((key) => data.errors[key]).flat();
      } else if (data[""]) {
        return data[""];
      }
    }
  } catch (error) {
    console.error("Error ", error);
    return undefined;
  }
};

export const getDeliveryAddresses = async (
  token: Session
): Promise<Address[]> => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_DELIVERY_ADDRESSES}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error", error);
    return [];
  }
};

export const postDeliveryAddresses = async (
  token: Session,
  addressline: string,
  postcode: string,
  city: string,
  country: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${POST_DELIVERY_ADDRESSES}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          addressline,
          postcode,
          city,
          country,
        }),
      }
    );
    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }
    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductsVaakSamenGekocht = async (
  token: Session,
  id: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_VAAK_SAMEN_GEKOCHT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
          PageNumber: 1,
          PageSize: 5,
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductsAndereSoorten = async (token: Session, id: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_ANDERE_SOORTEN}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
          PageNumber: 1,
          PageSize: 5,
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getProductsGekoppeldePopup = async (
  token: Session,
  id: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_PRODUCTS_GEKOPPELDE_POPUP}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          id,
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getCustomerSpecificProducts = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_CUSTOMER_SPECIFIC_PRODUCTS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getActiveBrands = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_ACTIVE_BRANDS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getLinkedCustomers = async (token: Session) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_LINKED_CUSTOMERS}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      return data.items;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const switchLinkedCustomer = async (
  token: Session,
  customerId: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${SWITCH_LINKED_CUSTOMER}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({ customerId }),
      }
    );
    const data = await response.json();
    if (data.success) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error: ", error);
    return false;
  }
};

export const getLists = async (token: Session) => {
  try {
    const response = await fetch(`${BASE_URL}/${FACTORY_STRING}/${GET_LISTS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
    });

    const data = await response.json();
    if (data.success && data.items) {
      const groupedItems = _.groupBy(data.items, "listId");

      const lists = _.map(groupedItems, (items, listId) => ({
        id: parseInt(listId),
        name: items[0].listName,
        products: _.orderBy(
          items
            .filter((item) => item.productCount !== null)
            .map((item) => ({
              count: item.productCount,
              thumbnailUrl: item.productThumbnailUrl,
            })),
          ["thumbnailUrl", "count"],
          ["asc", "desc"]
        ),
      }));

      const sortedLists = _.orderBy(lists, ["id"], ["asc"]);

      return sortedLists;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const getListProducts = async (token: Session, listId: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_LIST_PRODUCTS}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          listId,
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      const groupedItems = _.groupBy(data.items, "listId");

      const lists = _.map(groupedItems, (items) => ({
        id: listId,
        name: items[0].listName,
        products: _.orderBy(
          items
            .filter((item) => item.productCount !== null)
            .map((item) => ({
              count: item.productCount,
              thumbnailUrl: item.productThumbnailUrl,
              rowId: item.listRowId,
              id: item.productId,
              description: item.productDescription,
              brandId: item.productBrandId,
              brandName: item.productBrandName,
              instruction: item.instruction,
              readyMadeArticle: item.readyMadeArticle,
            })),
          "description"
        ),
      }));

      return lists[0];
    }

    return undefined;
  } catch (error) {
    console.error("Error: ", error);
    return undefined;
  }
};

export const addListToCart = async (token: Session, listId: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${ADD_LIST_TO_CART}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          listId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error adding list to cart: ", error);
    return [];
  }
};

export const deleteList = async (token: Session, listId: number) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${DELETE_LIST}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          listId,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error deleting list: ", error);
    return [];
  }
};

export const getListNames = async (token: Session, itemId: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${GET_LIST_NAMES_V2}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          itemId,
        }),
      }
    );

    const data = await response.json();
    if (data.success && data.items) {
      const groupedItems = _.groupBy(data.items, "listId");

      console.log(groupedItems);

      const lists = _.map(groupedItems, (items, listId) => ({
        id: parseInt(listId),
        name: items[0].listName,
        inList: items[0].inList,
      }));

      const sortedLists = _.orderBy(lists, "name");

      console.log(sortedLists);

      return sortedLists;
    }

    return [];
  } catch (error) {
    console.error("Error: ", error);
    return [];
  }
};

export const addList = async (token: Session, name: string) => {
  try {
    const response = await fetch(`${BASE_URL}/${FACTORY_STRING}/${ADD_LIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
      body: JSON.stringify({
        name,
      }),
    });
    const data = await response.json();

    if (data.success) {
      return data.newId;
    }

    return undefined;
  } catch (error) {
    console.error("Error adding list: ", error);
    return undefined;
  }
};

export const addProductToList = async (
  token: Session,
  itemId: number,
  listIds: string,
  instruction: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${ADD_PRODUCT_TO_LIST_V3}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          itemId,
          listIds,
          instruction
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error adding product to list: ", error);
    return undefined;
  }
};

export const editListProductCount = async (
  token: Session,
  listRowId: number,
  count: number
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${EDIT_LIST_PRODUCT_COUNT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          listRowId,
          count,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error changing product list count: ", error);
    return undefined;
  }
};

export const editList = async (
  token: Session,
  listId: number,
  name: string
) => {
  try {
    const response = await fetch(`${BASE_URL}/${FACTORY_STRING}/${EDIT_LIST}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.accessToken}`,
      },
      body: JSON.stringify({
        listId,
        name,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error changing list name: ", error);
    return undefined;
  }
};

export const editListProductInstruction = async (
  token: Session,
  listRowId: number,
  instruction: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/${FACTORY_STRING}/${EDIT_LIST_PRODUCT_INSTRUCTION}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
        body: JSON.stringify({
          listRowId,
          instruction,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    } else {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error changing product list count: ", error);
    return undefined;
  }
};

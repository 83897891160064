// Libraries
import { useCallback, useEffect, useReducer, useState } from "react";
import {
  faFile,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faCircleExclamation,
  faListUl,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCartCircleExclamation,
  faCheck,
  faShoppingCart,
  faMessageLines as noInstructionIcon,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronLeft,
  faSpinnerThird,
  faMessageLines as instructionIcon,
} from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import ReactGA from "react-ga4";

// Components
import ButtonShoppingCart from "../components/ButtonShoppingCart";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { useProtectedRoute } from "../hooks/ProtectedRoute";
import {
  fetchCart,
  emptyCart,
  postShoppingCartByCartId,
  completeShoppingCartAsInvoice,
  getShoppingCartCount,
  completeShoppingcart,
  getDeliveryAddresses,
} from "../api";

// Models
import { sessionAtom } from "../models/Session";
import { colorsAtom } from "../models/Colors";
import { Cart as CartT, cartTotalAtom } from "../models/Cart";
import { userAtom } from "../models/User";
import { cartAtom } from "../models/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product } from "../models/Product";
import { formatCurrency, slugify } from "../functions";
import FeedbackCartAction from "../components/FeedbackCartAction";
import Input from "../components/Input";
import { Address } from "../models/Address";
import { useNavigation } from "../components/NavigationHandler";
import _ from "lodash";

const Cart = () => {
  useProtectedRoute();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateBack } = useNavigation();

  const [session, setSession] = useAtom(sessionAtom);
  const [user, setUser] = useAtom(userAtom);
  const [cart, setCart] = useAtom(cartAtom);
  const [, setCartTotal] = useAtom(cartTotalAtom);
  const [colors] = useAtom(colorsAtom);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [selectAll, setSelectAll] = useState(true);

  const [instructionModalOpen, setInstructionModalOpen] = useState(false);
  const [instructions, setInstructions] = useState<string>("");
  const [orderInstruction, setOrderInstruction] = useState<string>(
    localStorage.getItem("cartInstruction") ?? ""
  );
  const [currentProduct, setCurrentProduct] = useState<CartT | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isDivVisible, setDivVisible] = useState(false);
  const [isDivVisibleInvoice, setDivVisibleInvoice] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [messageColor, setMessageColor] = useState<0 | 1>(0);
  document.title = "Winkelwagen | Altac Bestelapp";

  const [otherAddress, setOtherAddress] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [inputStreet, setInputStreet] = useState("");
  const [inputZip, setInputZip] = useState("");
  const [inputCity, setInputCity] = useState("");
  const [inputCountry, setInputCountry] = useState("");
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [acceptedTermsConditions, setAcceptedTermsConditions] =
    useState<boolean>(false);
  const [selectedAddressId, setSelectedAddressId] = useState<
    number | undefined
  >(undefined);

  localStorage.getItem("cookies_accept") === "true" &&
    ReactGA.send({
      hitType: "pageview",
      page: "/cart",
      title: "Winkelwagen",
    });

  // Select item function
  const toggleItem = (userIndex: number, index: number) => {
    cart![userIndex].products[index].selected =
      !cart![userIndex].products[index].selected;
    setCart(cart);
    console.log(cart);
    forceUpdate();
  };

  // // Select all function
  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    for (var i = 0; i < cart!.length; i++) {
      for (var j = 0; j < cart![i].products.length; j++) {
        cart![i].products[j].selected = !selectAll;
      }
    }
    setCart(cart);
    forceUpdate();
  };

  // Get all cart items function
  const fetchCartItems = useCallback(async () => {
    if (session && user) {
      try {
        setIsLoading(true);

        const cartData = await fetchCart(session, user.email);
        setIsLoading(false);
        cartData.forEach((userCart) => {
          userCart.products.forEach((item) => {
            item.selected = true;
          });
        });
        setCart([
          cartData[0],
          ..._.sortBy([...cartData.slice(1)], "type").reverse(),
        ]);
        await getShoppingCartCount(session).then((count) =>
          setCartTotal(typeof count === "number" ? count : 0)
        );
      } catch (error) {
        console.error("Cart error: ", error);
      }
    }
  }, [session, user, setCart, setInstructions]);

  const updateCartProduct = async (
    scid: number,
    number: number,
    instruction: string | undefined
  ) => {
    if (session) {
      postShoppingCartByCartId(
        session,
        scid,
        number,
        typeof instruction === "string" ? instruction : ""
      ).then(() => fetchCartItems());
    }
  };

  const updateCartEmpty = async (scIds: string) => {
    const confirmDelete = window.confirm(
      "Weet u zeker dat u de winkelwagen wil legen?"
    );
    if (confirmDelete) {
      try {
        if (session) {
          emptyCart(session, scIds).then(() => fetchCartItems());
        }
      } catch (error) {
        console.error("Error emptying the cart: ", error);
      }
    }
  };

  const completeOrder = async () => {
    if (session) {
      const selectedProducts = cart
        ?.map((cartUser) =>
          cartUser.products
            .filter((item) => item.selected)
            .map((item) => item.scid)
        )
        .filter((productsArray) => productsArray.length > 0)
        .map((productsArray) => productsArray.join(","))
        .join(",");

      const defaultAddress = !otherAddress
        ? addresses.find((address) => address.IsDefault)
        : undefined;

      if (selectedProducts?.length && acceptedTermsConditions) {
        completeShoppingcart(
          session,
          selectedProducts,
          orderInstruction,
          defaultAddress?.addressline ?? inputStreet,
          defaultAddress?.postcode ?? inputZip,
          defaultAddress?.city ?? inputCity,
          defaultAddress?.country ?? inputCountry
        )
          .then(() => fetchCartItems())
          .then(() => {
            setMessageColor(1);
            setMessage("De geselecteerde producten zijn besteld");
            setOrderInstruction("");
            localStorage.removeItem("cartInstruction");
          });
      } else if (!acceptedTermsConditions) {
        setMessageColor(0);
        setMessage("Je dient akkoord te gaan met de algemene voorwaarden");
      } else {
        setMessageColor(0);
        setMessage("Er zijn geen producten geselecteerd");
      }
    }
  };

  const getOrderInvoice = async () => {
    if (session) {
      const selectedProducts = cart
        ?.map((cartUser) =>
          cartUser.products
            .filter((item) => item.selected)
            .map((item) => item.scid)
        )
        .filter((productsArray) => productsArray.length > 0)
        .map((productsArray) => productsArray.join(","))
        .join(",");

      if (selectedProducts?.length && acceptedTermsConditions) {
        completeShoppingCartAsInvoice(
          session,
          selectedProducts,
          orderInstruction
        )
          .then(() => fetchCartItems())
          .then(() => {
            setMessageColor(1);
            setMessage(
              "Er wordt een prijsopgave opgesteld voor de geselecteerde producten"
            );
            setOrderInstruction("");
            localStorage.removeItem("cartInstruction");
          });
      } else if (!acceptedTermsConditions) {
        setMessageColor(0);
        setMessage("Je dient akkoord te gaan met de algemene voorwaarden");
      } else {
        setMessageColor(0);
        setMessage("Er zijn geen producten geselecteerd");
      }
    }
  };

  const handleOnChangeAddress = (callback: {
    value: string;
    name?: string;
  }) => {
    switch (callback.name) {
      case "zip":
        setInputZip(callback.value);
        break;
      case "city":
        setInputCity(callback.value);
        break;
      case "street":
        setInputStreet(callback.value);
        break;
      case "country":
        setInputCountry(callback.value);
        break;
    }
  };

  // Fetch cart items
  useEffect(() => {
    fetchCartItems();
  }, [fetchCartItems]);

  useEffect(() => {
    if (session) {
      getDeliveryAddresses(session).then((data) => {
        setAddresses(data);
        const defaultAddress = data.find((address) => address.IsDefault);
        if (defaultAddress) {
          defaultAddress?.addressline &&
            setInputStreet(defaultAddress.addressline);
          defaultAddress?.city && setInputCity(defaultAddress.city);
          defaultAddress?.country && setInputCountry(defaultAddress.country);
          defaultAddress?.postcode && setInputZip(defaultAddress.postcode);
        } else {
          setOtherAddress(true);
          if (data.length) {
            setSelectedAddressId(data[0].id);
            setInputStreet(data[0]?.addressline ?? "");
            setInputCity(data[0]?.city ?? "");
            setInputCountry(data[0]?.country ?? "");
            setInputZip(data[0]?.postcode ?? "");
          }
        }
      });
    }
  }, []);

  return (
    <div className="w-full flex flex-col h-screen md:h-fit">
      <div
        className="flex-row items-center gap-3 font-bold mt-8 w-full max-w-[1420px] mx-auto pl-4 md:pl-6 2xl:pl-0 hidden md:flex"
        style={{ color: colors?.primaryColor }}
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-sm" />
        <button onClick={() => navigate(-1)}>Terug</button>
      </div>
      <div className="flex flex-col gap-4 max-w-[1420px] w-full mx-auto px-0 md:px-6 2xl:px-0 pb-32 md:pb-0">
        <div
          className="flex border-b md:border-b-0 items-center h-fit"
          style={{ borderColor: colors?.neutralColor }}
        >
          <div
            className="absolute left-0 p-4 md:hidden"
            onClick={() => navigate(-1)}
          >
            <Button icon={faArrowLeft} sm light noPadding noRing />
          </div>
          <div className="flex flex-row justify-between w-full items-center">
            <p className="w-full py-4 md:text-xl font-bold text-center md:text-left mt-2">
              {t("cart.title")}
            </p>
          </div>
        </div>
        <div className="px-0 flex flex-col pb-24">
          <div
            className="border-b pb-3 px-4 md:px-0"
            style={{ borderColor: colors?.neutralColor }}
          >
            <Checkbox onChange={toggleSelectAll} checked={selectAll} boxOnly>
              <div className="w-full flex items-center justify-between h-10 font-bold">
                <span>Selecteer alles</span>
                <Button
                  icon={faTrashAlt}
                  sm
                  light
                  onClick={() => {
                    const selectedProducts = cart
                      ?.map((cartUser) =>
                        cartUser.products
                          .filter((item) => item.selected)
                          .map((item) => item.scid)
                      )
                      .filter((productsArray) => productsArray.length > 0)
                      .map((productsArray) => productsArray.join(","))
                      .join(",");

                    if (selectedProducts?.length) {
                      updateCartEmpty(selectedProducts);
                    }
                  }}
                />
              </div>
            </Checkbox>
          </div>
          {cart && cart.length > 0 ? (
            cart.map((cartUser, userIndex) => (
              <div key={`cartUser-${userIndex}`} className="mb-4">
                {userIndex - 1 === _.findIndex(cart, ["type", "user"]) ? (
                  <p className="text-xl font-bold mt-16 mx-4 md:mx-0">
                    Meer winkelwagens
                  </p>
                ) : (
                  <></>
                )}
                {userIndex === _.findIndex(cart, ["type", "list"]) ? (
                  <p className="text-xl font-bold mt-16 mx-4 md:mx-0">
                    Producten uit lijsten
                  </p>
                ) : (
                  <></>
                )}
                {userIndex > 0 && (
                  <p className="mt-6 mb-2 flex flex-row gap-3 items-center font-bold mx-4 md:mx-0">
                    <FontAwesomeIcon
                      icon={cartUser.type === "user" ? faUser : faListUl}
                    />
                    {cartUser.name}
                  </p>
                )}
                {cartUser.products.map((product, index) => (
                  <div
                    className="border-b py-3 px-4 md:px-0"
                    style={{ borderColor: colors?.neutralColor }}
                    key={`cartUser-${userIndex}-${product.scid}`}
                  >
                    <Checkbox
                      onChange={() => toggleItem(userIndex, index)}
                      checked={product.selected}
                      boxOnly
                    >
                      <div className="flex flex-row items-center min-h-10 gap-0 sm:gap-4 w-full">
                        <Link
                          to={`/product/${slugify(
                            product.mainProductDescription ||
                              product.description
                          )}/${
                            product.mainItemId
                              ? `${product.mainItemId}/${product.productId}`
                              : product.productId
                          }`}
                          className="flex-shrink-0"
                        >
                          <img
                            src={
                              typeof product?.thumbnailUrl === "string"
                                ? product.thumbnailUrl
                                : "../../img/product-placeholder-thumbnail.png"
                            }
                            className="h-0 w-0 sm:h-12 sm:w-12 object-contain"
                          />
                        </Link>

                        <div className="flex flex-col flex-grow-0 gap-1 justify-between w-full">
                          <div className="flex flex-row items-center gap-2 text-xs w-fit">
                            <p
                              className="whitespace-normal font-bold"
                              style={{ color: colors?.textLightColor }}
                            >
                              {product.brand !== null && product.brand !== ""
                                ? product.brand
                                : "Merkloos"}
                            </p>
                            {product.backorder ? (
                              <div className="relative flex items-center flex-row justify-center group">
                                <div className="flex items-center w-fit">
                                  <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className="text-sm"
                                    style={{ color: colors?.primaryColor }}
                                  />
                                  <div className="absolute w-max max-w-[200px] bg-white mx-auto z-30 py-3 px-5 shadow rounded-md scale-0 group-hover:scale-100 -translate-y-1/4 group-hover:-translate-y-11 opacity-0 group-hover:opacity-100 duration-200 transition-all -translate-x-1/2">
                                    Dit product staat {product.backorder} keer
                                    in de backorder
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            <button
                              onClick={() => {
                                setInstructions(
                                  typeof product.instruction === "string"
                                    ? product.instruction
                                    : ""
                                );
                                setCurrentProduct(product);
                                setInstructionModalOpen(true);
                              }}
                            >
                              <span className="flex align-center items-center">
                                <FontAwesomeIcon
                                  icon={
                                    typeof product.instruction === "string" &&
                                    product.instruction !== ""
                                      ? instructionIcon
                                      : noInstructionIcon
                                  }
                                  className="mr-2 text-sm"
                                  style={{ color: colors?.primaryColor }}
                                />
                              </span>
                            </button>
                          </div>

                          <Link
                            to={`/product/${slugify(
                              product.mainProductDescription ||
                                product.description
                            )}/${
                              product.mainItemId
                                ? `${product.mainItemId}/${product.productId}`
                                : product.productId
                            }`}
                          >
                            <p className="text-sm whitespace-normal font-bold line-clamp-2">
                              {product.mainProductDescription ||
                                product.description}
                            </p>
                          </Link>
                          {typeof product.instruction === "string" && (
                            <p
                              className="text-xs italic line-clamp-1"
                              style={{ color: colors?.textLightColor }}
                            >
                              {product.instruction}
                            </p>
                          )}
                          <p className="text-sm font-bold block sm:hidden">
                            {[
                              "BrutoPrices",
                              "NettoPrices",
                              "Representative",
                            ].some((role) => user?.roles?.includes(role)) &&
                            product.price
                              ? formatCurrency(product.price)
                              : ""}
                          </p>
                        </div>
                        <div className="flex-shrink-0 flex gap-4 items-center pl-3">
                          <p className="text-sm font-bold hidden sm:block">
                            {[
                              "BrutoPrices",
                              "NettoPrices",
                              "Representative",
                            ].some((role) => user?.roles?.includes(role)) &&
                            product.price
                              ? formatCurrency(product.price)
                              : ""}
                          </p>
                          <ButtonShoppingCart
                            amount={product.number}
                            onValueChange={(value: number) => {
                              updateCartProduct(
                                product.scid,
                                value,
                                product.instruction
                              );
                            }}
                            backorder={product.backorder}
                          />
                        </div>
                      </div>
                    </Checkbox>
                  </div>
                ))}
                {cart[userIndex].products.length === 0 &&
                  (isLoading ? (
                    <FontAwesomeIcon
                      icon={faSpinnerThird}
                      className="mt-4 animate-spin duration-1000 text-xl"
                    />
                  ) : (
                    <p
                      className="italic mx-4 md:mx-0 my-4"
                      style={{ color: colors?.textLightColor }}
                    >
                      De winkelwagen is leeg
                    </p>
                  ))}
              </div>
            ))
          ) : (
            <></>
          )}
          {["Approver", "Representative"].some((role) =>
            user?.roles?.includes(role)
          ) ? (
            <textarea
              className="h-24 border rounded-md p-2 mt-4 mx-4 md:mx-0"
              placeholder="Voeg hier een omschrijving toe voor de bestelling of prijsopgave"
              value={orderInstruction}
              style={{
                borderColor: colors?.neutralColor,
                backgroundColor: colors?.neutralLightColor,
              }}
              onChange={(input) => {
                setOrderInstruction(input.target.value);
                localStorage.setItem("cartInstruction", input.target.value);
              }}
            />
          ) : (
            <></>
          )}
          {["BrutoPrices", "NettoPrices", "Representative"].some((role) =>
            user?.roles?.includes(role)
          ) ? (
            <div className="flex flex-row justify-between my-8 px-6 md:px-0">
              <p>Totaalprijs geselecteerde producten</p>
              <p className="font-bold">
                {cart &&
                  formatCurrency(
                    cart
                      .flatMap((user) => user.products)
                      .filter((product) => product.selected)
                      .reduce((sum, product) => sum + (product.price || 0), 0)
                  )}
              </p>
            </div>
          ) : (
            <></>
          )}
          {["Approver", "Representative"].some((role) =>
            user?.roles?.includes(role)
          ) ? (
            <div className="flex flex-col gap-3 px-4 md:px-0 my-4 w-full">
              <Checkbox
                checked={acceptedTermsConditions}
                onChange={() =>
                  setAcceptedTermsConditions(!acceptedTermsConditions)
                }
              >
                <span>
                  Ik ga akkoord met de{" "}
                  <Link
                    to="https://www.altac.nl/algemene-voorwaarden/"
                    target="_blank"
                    className="font-bold"
                    style={{ color: colors?.primaryColor }}
                  >
                    algemene voorwaarden
                  </Link>
                </span>
              </Checkbox>
              <div className="mb-4">
                {addresses.map((address) =>
                  address.IsDefault && !otherAddress ? (
                    <div className="mb-4 flex flex-col">
                      <p className="font-bold mb-2">Afleveradres</p>
                      <p>{address.addressline}</p>
                      <p>
                        {address.postcode}, {address.city}, {address.country}
                      </p>
                    </div>
                  ) : (
                    <></>
                  )
                )}
                <Checkbox
                  checked={otherAddress}
                  onChange={() => {
                    setOtherAddress(!otherAddress);
                    if (addresses.length > 0 && !otherAddress) {
                      setInputStreet(addresses[0].addressline ?? "");
                      setInputZip(addresses[0].postcode ?? "");
                      setInputCity(addresses[0].city ?? "");
                      setInputCountry(addresses[0].country ?? "");
                      const defaultAddress = addresses.find(
                        (address) => address.IsDefault
                      );
                      console.log(defaultAddress);
                      if (defaultAddress) {
                        setSelectedAddressId(defaultAddress.id ?? undefined);
                        setInputStreet(defaultAddress.addressline ?? "");
                        setInputZip(defaultAddress.postcode ?? "");
                        setInputCity(defaultAddress.city ?? "");
                        setInputCountry(defaultAddress.country ?? "");
                      } else {
                        setSelectedAddressId(addresses[0].id ?? undefined);
                        setInputStreet(addresses[0].addressline ?? "");
                        setInputZip(addresses[0].postcode ?? "");
                        setInputCity(addresses[0].city ?? "");
                        setInputCountry(addresses[0].country ?? "");
                      }
                    } else {
                      setInputStreet("");
                      setInputZip("");
                      setInputCity("");
                      setInputCountry("");
                      setShowInput(false);
                    }
                  }}
                >
                  Ander afleveradres gebruiken
                </Checkbox>
              </div>
              {otherAddress ? (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 select-none">
                    {addresses && addresses.length > 0 ? (
                      addresses.map((address) => (
                        <div
                          className="w-full h-20 flex items-center px-5 py-3 rounded-md text-left border cursor-pointer"
                          style={
                            address.id === selectedAddressId
                              ? {
                                  borderColor: colors?.primaryColor,
                                  backgroundColor: "#ffffff",
                                  color: "#000000",
                                }
                              : {
                                  borderColor: colors?.neutralColor,
                                  backgroundColor: colors?.neutralLightColor,
                                  color: colors?.textLightColor,
                                }
                          }
                          onClick={() => {
                            setInputStreet(address.addressline ?? "");
                            setInputZip(address.postcode ?? "");
                            setInputCity(address.city ?? "");
                            setInputCountry(address.country ?? "");
                            setShowInput(false);
                            setSelectedAddressId(address.id);
                          }}
                        >
                          <div className="w-full">
                            <p className="truncate line-clamp-1">
                              {address.addressline}
                            </p>
                            <p className="truncate line-clamp-1">
                              {address.postcode}, {address.city},{" "}
                              {address.country}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                    {addresses && addresses.length > 0 ? (
                      <div
                        className="w-full h-20 flex items-center px-5 py-3 rounded-md text-left bg-white border transition-all cursor-pointer"
                        style={
                          showInput
                            ? {
                                borderColor: colors?.primaryColor,
                                backgroundColor: "#ffffff",
                                color: "#000000",
                              }
                            : {
                                borderColor: colors?.neutralColor,
                                backgroundColor: colors?.neutralLightColor,
                                color: colors?.textLightColor,
                              }
                        }
                        onClick={() => {
                          setSelectedAddressId(undefined);
                          setInputStreet("");
                          setInputZip("");
                          setInputCity("");
                          setInputCountry("");
                          setShowInput(true);
                        }}
                      >
                        <p className="w-fit mx-auto">Ander afleveradres</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {showInput || addresses.length === 0 ? (
                    <div
                      className={`flex flex-col gap-3 ${
                        addresses.length > 0 ? "mt-8" : ""
                      }`}
                    >
                      <Input
                        type="text"
                        name="street"
                        value={inputStreet}
                        placeholder="Straatnaam en huisnummer"
                        onChange={handleOnChangeAddress}
                      />
                      <div className="flex flex-row gap-3">
                        <Input
                          type="text"
                          name="zip"
                          value={inputZip}
                          placeholder="Postcode"
                          onChange={handleOnChangeAddress}
                        />
                        <Input
                          type="text"
                          name="city"
                          value={inputCity}
                          placeholder="Plaatsnaam"
                          onChange={handleOnChangeAddress}
                        />
                        <Input
                          type="text"
                          name="country"
                          value={inputCountry}
                          placeholder="Land"
                          onChange={handleOnChangeAddress}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {["Approver", "Representative"].some((role) =>
            user?.roles?.includes(role)
          ) ? (
            <div className="w-full hidden md:flex justify-end gap-4 py-8">
              <Button
                label={t("cart.quotation")!}
                icon={faFile}
                onClick={getOrderInvoice}
                light
              />
              <Button
                label={t("cart.order")!}
                icon={faShoppingCart}
                onClick={() => {
                  if (
                    inputCity !== "" &&
                    inputCountry !== "" &&
                    inputStreet !== "" &&
                    inputZip !== ""
                  ) {
                    completeOrder();
                  } else {
                    setMessageColor(0);
                    setMessage("Er is geen adres ingevuld");
                  }
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {["Approver", "Representative"].some((role) =>
        user?.roles?.includes(role)
      ) ? (
        <div
          className="z-20 fixed md:hidden bottom-0 w-full pb-16 border-t"
          style={{
            backgroundColor: colors?.neutralLightColor,
            borderColor: colors?.neutralColor,
          }}
        >
          <div className="m-4 flex flex-col gap-4">
            <Button
              label={t("cart.order")!}
              onClick={() => {
                if (
                  inputCity !== "" &&
                  inputCountry !== "" &&
                  inputStreet !== "" &&
                  inputZip !== ""
                ) {
                  completeOrder();
                } else {
                  setMessageColor(0);
                  setMessage("Er is geen adres ingevuld");
                }
              }}
              full
            />
            <Button
              label={t("cart.quotation")!}
              onClick={getOrderInvoice}
              full
              light
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {instructionModalOpen && currentProduct && (
        <div className="fixed w-screen h-screen z-50 bg-black/30 top-0 left-0 p-4 flex items-center">
          <div className="bg-white rounded-lg w-full p-6 max-w-lg mx-auto flex flex-col gap-4 text-right">
            <div className="flex flex-row items-center justify-between">
              <p className="font-bold">Instructie toevoegen</p>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setInstructionModalOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <textarea
              className="h-24 border rounded-md p-2"
              placeholder="Voeg hier een omschrijving toe"
              style={{
                borderColor: colors?.neutralColor,
                backgroundColor: colors?.neutralLightColor,
              }}
              onChange={(input) => setInstructions(input.target.value)}
            >
              {instructions}
            </textarea>
            <Button
              icon={faSave}
              label="Instructie opslaan"
              onClick={() => {
                updateCartProduct(
                  currentProduct.scid,
                  currentProduct.number,
                  instructions
                );
                setInstructionModalOpen(false);
              }}
            />
          </div>
        </div>
      )}
      <FeedbackCartAction
        ms={5000}
        message={message}
        color={messageColor}
        callback={() => setMessage("")}
      />
    </div>
  );
};

export default Cart;
